* {
    font-family: 'Poppins', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.innerWidth {
    width: 100%;
}

.paddings {
    padding: 2rem;
}

.flexCenter {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media (min-width: 1536px) {
    .innerWidth {
        max-width: 1280px;
        margin: auto;
    }
}

.primaryText {
    color: white;
    font-weight: bold;
    font-size: 2rem;
}

.secondaryText {
    color: rgb(140 139 139);
    font-size: 0.9rem;
}

.hText {
    color: orange;
    font-weight: bold;
    font-size: 2rem;
}

.orangeText {
    color: orange;
    font-size: 1.5rem;
    font-weight: 600;
}

.button {
    font-weight: 500;
    padding: 0.6rem 1.4rem;
    color: white;
    background: linear-gradient(97.05deg, rgb(230, 197, 11) 3.76%, orange 100%);
    border: none;
    border-radius: 4px;
    transition: all 300ms ease-in;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: #000;
}

.flexColStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flexColCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.secondaryText {
    color: rgb(140, 139, 139);
    font-size: 0.9rem;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
    .paddings {
        padding: 1.5rem;
    }
}

@media (max-width: 992px) {
    .paddings {
        padding: 1rem;
    }
}

@media (max-width: 768px) {
    .paddings {
        padding: 0.5rem;
    }

    .primaryText,
    .hText {
        font-size: 1.5rem;
    }

    .orangeText {
        font-size: 1.2rem;
    }

    .button {
        padding: 0.5rem 1.2rem;
    }
}

@media (max-width: 576px) {
    .primaryText,
    .hText {
        font-size: 1.2rem;
    }

    .orangeText {
        font-size: 1rem;
    }

    .button {
        padding: 0.4rem 1rem;
    }
}

  
